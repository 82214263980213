/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import { sprintf } from 'sprintf-js';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import ShopsContext from './ShopsContext';

Modal.setAppElement('#root');

const makeColorFromTime = (hhmm: string) => {
  const minutes = Number.parseInt(hhmm.substring(3), 10);
  const nth = Math.floor(minutes / 5);
  const colors = [
    'ffcccc',
    'ff80cc',
    'ffffcc',
    '80ffcc',
    'ccffcc',
    'ccff80',
    'ccffff',
    'cc80ff',
    'ccccff',
    '80ccff',
    'ffccff',
    'ffcc80',
  ];

  return `#${colors[nth]}`;
};

const validateTime = (time: string) => {
  const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0,5]$/;
  return re.test(time);
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const hourMinutes = (minutes: number) => {
  const hour = Math.floor(minutes / 60);
  const min = minutes - hour * 60;
  return sprintf('%02d:%02d', hour, min);
};

const diffMinutes = (time1: string, time2: string) => {
  const [hour1, min1] = time1.split(':').map((s) => Number.parseInt(s, 10));
  const [hour2, min2] = time2.split(':').map((s) => Number.parseInt(s, 10));
  return (hour2 - hour1) * 60 + (min2 - min1);
};

function LineItem(props: {
  line: any;
  slotPattern: any;
  lineIndex: number;
  slotPatternId: string;
  slot: any;
  kitchenShopId: string;
}) {
  const [lineUpdateErrorMessage, setLineUpdateErrorMessage] = useState<string>();
  const [modalLineIsOpen, setModalLineIsOpen] = useState(false);

  const { line } = props;
  const { slotPattern } = props;
  const { lineIndex } = props;
  const { slotPatternId } = props;
  const { slot } = props;
  const { kitchenShopId } = props;

  const editLine = async (e) => {
    e.preventDefault();

    setLineUpdateErrorMessage('');
    setModalLineIsOpen(true);
  };

  const deleteLine = async (e) => {
    e.preventDefault();
    e.persist();

    if (await isOrderExist()) {
      setLineUpdateErrorMessage('注文が存在するため削除できません');
      return;
    }

    slotPattern.lines = slotPattern.lines.filter((_, index) => index !== lineIndex);

    await firebase.firestore().collection('slot_patterns').doc(slotPatternId).update(slotPattern);

    setModalLineIsOpen(false);
  };

  const cancelEditLine = (e) => {
    e.preventDefault();
    setModalLineIsOpen(false);
  };

  const isOrderExist = async () => {
    if (!line) {
      return false;
    }
    const records = await firebase
      .firestore()
      .collection('shops')
      .doc(kitchenShopId)
      .collection('cooking_slots')
      .where('slot_pattern_id', '==', slotPatternId)
      .where('slot_pattern_line_time', '==', line.time)
      .where('valid', '==', true)
      .where('order_id', '!=', null)
      .limit(1)
      .get();
    return records.size > 0;
  };

  const handleSubmitLineModal = async (e) => {
    e.preventDefault();
    e.persist();

    if (await isOrderExist()) {
      setLineUpdateErrorMessage('注文が存在するため変更できません');
      return;
    }

    const time = e.target.time.value;
    const timeUntil = e.target.timeUntil.value;
    const lines = Number.parseFloat(e.target.lines.value);
    const secondsPerCurry = Number.isNaN(e.target.seconds_per_curry.value)
      ? null
      : Number.parseInt(e.target.seconds_per_curry.value, 10);

    if (secondsPerCurry) {
      const minutes = diffMinutes(time, timeUntil);
      const curries = Math.floor((minutes * 60) / secondsPerCurry);
      if (curries < 1) {
        setLineUpdateErrorMessage('食数が1以上になるように秒数を設定してください');
        return;
      }
    }

    if (Number.isNaN(lines) || lines < 0) {
      setLineUpdateErrorMessage('ライン数を設定してください');
      return;
    }

    if (!validateTime(time)) {
      setLineUpdateErrorMessage('スロット開始時間は5分単位でHH:MMの書式で設定してください');
      return;
    }

    if (!validateTime(timeUntil)) {
      setLineUpdateErrorMessage('スロット終了時間は5分単位でHH:MMの書式で設定してください');
      return;
    }

    if (time >= timeUntil) {
      setLineUpdateErrorMessage('スロット終了時間は開始時間より後の時間を設定してください');
      return;
    }

    const duplicatedSlot = slotPattern.lines.find((argLine, index) => {
      if (index === lineIndex) {
        return false;
      }

      if (argLine.time < time && time < argLine.time_until) {
        return true;
      }

      if (argLine.time < timeUntil && timeUntil < argLine.time_until) {
        return true;
      }

      return false;
    });

    if (duplicatedSlot) {
      setLineUpdateErrorMessage('時間が重なっています');
      return;
    }

    if (lineIndex !== null && lineIndex >= 0) {
      slotPattern.lines[lineIndex] = {
        time,
        time_until: timeUntil,
        lines,
        seconds_per_curry: secondsPerCurry,
      };
    } else {
      slotPattern.lines.push({
        time,
        time_until: timeUntil,
        lines,
        seconds_per_curry: secondsPerCurry,
      });
    }

    slotPattern.lines.sort((a, b) => {
      return a.time > b.time ? 1 : -1;
    });

    await firebase.firestore().collection('slot_patterns').doc(slotPatternId).update(slotPattern);

    setModalLineIsOpen(false);
  };

  const ModalEditLine = () => {
    return (
      <Modal isOpen={modalLineIsOpen} style={customStyles} contentLabel="ライン編集">
        <div>
          <form id="editLine" onSubmit={handleSubmitLineModal}>
            <div className="form-group row">
              開始 (HH:MM)
              <input type="text" name="time" defaultValue={line?.time || hourMinutes(slot.time)} />
              終了 (HH:MM)
              <input type="text" name="timeUntil" defaultValue={line?.time_until || hourMinutes(slot.time + 15)} />
            </div>

            <div className="form-group row">
              ライン数
              <input type="text" name="lines" defaultValue={Number.isNaN(line?.lines) ? 1 : line?.lines} />
            </div>

            <div className="form-group row">
              1食あたりの盛り付け秒数
              <input
                type="number"
                min={0}
                max={999}
                name="seconds_per_curry"
                defaultValue={Number.isNaN(line?.seconds_per_curry) ? null : line?.seconds_per_curry}
              />
              <small className="text-danger">空の場合はデフォルト値が使用されます。</small>
            </div>

            <div className="form-group row">
              <small className="text-danger">{lineUpdateErrorMessage}</small>
            </div>

            <div className="form-group row">
              <div className="col-12">
                <input type="submit" className="btn btn-primary" value="更新" />
                <input
                  type="button"
                  className="btn btn-dark"
                  value="キャンセル"
                  onClick={(e) => {
                    cancelEditLine(e);
                  }}
                />
                <button
                  type="button"
                  className="btn btn-danger float-right"
                  onClick={(e) => {
                    deleteLine(e);
                  }}
                >
                  削除
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  if (line) {
    const minutes = diffMinutes(line.time, line.time_until);
    const curries = Math.floor((minutes * 60) / (line.seconds_per_curry || slotPattern.seconds_per_curry)) * line.lines;

    return (
      <>
        <td
          rowSpan={minutes / 5}
          style={{
            backgroundColor: makeColorFromTime(line.time),
            border: '1px solid black',
          }}
        >
          <ModalEditLine />
          <div>
            <button
              type="button"
              onClick={(e) => {
                editLine(e);
              }}
            >
              <i className="far fa-edit fa-xs" />
            </button>
            {line.time}-{line.time_until} ({line.lines}){curries}食{' '}
            {line.seconds_per_curry ? `(${line.seconds_per_curry}秒/食)` : ''}
          </div>
        </td>
      </>
    );
  }
  const line2 = slotPattern.lines.find((s) => s.time < hourMinutes(slot.time) && s.time_until > hourMinutes(slot.time));

  if (line2) {
    return <></>;
  }
  return (
    <td>
      <ModalEditLine />
      <button
        type="button"
        onClick={(e) => {
          editLine(e);
        }}
      >
        <i className="far fa-plus fa-xs" />
      </button>
    </td>
  );
}

function SlotItem(props: {
  line: any;
  slotPattern: any;
  slot: any;
  batchDelivery: boolean;
  batchDeliveryCloseMinutes: number;
  batchDeliveryDelayedCookingMinutes: number;
  kitchenShopId: string;
  shopId: string;
  slotIndex: number;
  slotPatternId: string;
  slotPatternForShop: any;
  deliveryGroups: Array<{ id: string; name: string; limitCurriesPerSlot: number | null; position: number }>;
}) {
  const [slotUpdateErrorMessage, setSlotUpdateErrorMessage] = useState<string>();
  const [modalSlotIsOpen, setModalSlotIsOpen] = useState(false);
  const { line } = props;
  const { slotPattern } = props;
  const { slot } = props;
  const { batchDelivery } = props;
  const { batchDeliveryCloseMinutes } = props;
  const { batchDeliveryDelayedCookingMinutes } = props;
  const { kitchenShopId } = props;
  const { shopId } = props;
  const { slotIndex } = props;
  const { slotPatternId } = props;
  const { slotPatternForShop } = props;

  const isOrderExist = async () => {
    const records = await firebase
      .firestore()
      .collection('shops')
      .doc(kitchenShopId)
      .collection('cooking_slots')
      .where('shop_id', '==', shopId)
      .where('slot_pattern_id', '==', slotPatternId)
      .where('slot_pattern_slot_time', '==', hourMinutes(slot.time))
      .where('valid', '==', true)
      .where('order_id', '!=', null)
      .limit(1)
      .get();
    return records.size > 0;
  };

  const openEditSlot = async (e) => {
    e.preventDefault();

    setSlotUpdateErrorMessage('');
    setModalSlotIsOpen(true);
  };

  const cancelEditSlot = (e) => {
    e.preventDefault();
    setModalSlotIsOpen(false);
  };

  const handleSubmitSlotModal = async (e) => {
    e.preventDefault();
    e.persist();

    if (await isOrderExist()) {
      setSlotUpdateErrorMessage('注文が存在するため変更できません');
      return;
    }

    const time = e.target.time.value;
    const timeUntil = e.target.timeUntil.value;
    const newBatchDelivery = !!batchDelivery;
    const deliveryGroupId = e.target.deliveryGroupId ? e.target.deliveryGroupId.value || null : null;
    const limitCurries = Number.parseInt(e.target.limitCurries.value, 10) || null;

    const newBatchDeliveryCloseMinutes = e.target.batchDeliveryCloseMinutes
      ? Number.parseInt(e.target.batchDeliveryCloseMinutes.value, 10) || null
      : null;
    const newBatchDeliveryDelayedCookingMinutes = e.target.batchDeliveryDelayedCookingMinutes
      ? Number.parseInt(e.target.batchDeliveryDelayedCookingMinutes.value, 10) || null
      : null;
    const newMinutesFromSlotSetToDelivery = e.target.minutesFromSlotSetToDelivery
      ? Number.parseInt(e.target.minutesFromSlotSetToDelivery.value, 10) || null
      : null;

    if (!validateTime(time)) {
      setSlotUpdateErrorMessage('スロット開始時間は5分単位でHH:MMの書式で設定してください');
      return;
    }

    if (!validateTime(timeUntil)) {
      setSlotUpdateErrorMessage('スロット終了時間は5分単位でHH:MMの書式で設定してください');
      return;
    }

    if (time >= timeUntil) {
      setSlotUpdateErrorMessage('スロット終了時間は開始時間より後の時間を設定してください');
      return;
    }

    let slotPatternForShopIndex = slotPattern.shops.findIndex((s) => s.id === shopId);
    if (slotPatternForShopIndex < 0) {
      slotPattern.shops.push({
        id: shopId,
        slots: [],
      });
      slotPatternForShopIndex = slotPattern.shops.length - 1;
    }

    const duplicatedSlot = slotPattern.shops[slotPatternForShopIndex].slots.find((argSlot, index) => {
      if (index === slotIndex) {
        return false;
      }

      if (argSlot.time < time && time < argSlot.time_until) {
        return true;
      }

      if (argSlot.time < timeUntil && timeUntil < argSlot.time_until) {
        return true;
      }

      return false;
    });

    if (duplicatedSlot) {
      setSlotUpdateErrorMessage('時間が重なっています');
      return;
    }

    const values = {
      time,
      time_until: timeUntil,
      batch_delivery: newBatchDelivery,
      delivery_group_id: deliveryGroupId,
      batch_delivery_close_minutes: newBatchDeliveryCloseMinutes,
      batch_delivery_delayed_cooking_minutes: newBatchDeliveryDelayedCookingMinutes,
      limit_curries: limitCurries,
      minutes_from_slot_set_to_delivery: newMinutesFromSlotSetToDelivery,
    };

    if (slotIndex !== null && slotIndex >= 0) {
      slotPattern.shops[slotPatternForShopIndex].slots[slotIndex] = values;
    } else {
      slotPattern.shops[slotPatternForShopIndex].slots.push(values);
    }

    slotPattern.shops[slotPatternForShopIndex].slots.sort((a, b) => {
      return a.time > b.time ? 1 : -1;
    });

    await firebase.firestore().collection('slot_patterns').doc(slotPatternId).update(slotPattern);

    setModalSlotIsOpen(false);
  };

  const deleteSlot = async (e) => {
    e.preventDefault();
    e.persist();

    if (await isOrderExist()) {
      setSlotUpdateErrorMessage('注文が存在するため削除できません');
      return;
    }

    const slotPatternForShopIndex = slotPattern.shops.findIndex((s) => s.id === shopId);

    slotPattern.shops[slotPatternForShopIndex].slots = slotPattern.shops[slotPatternForShopIndex].slots.filter(
      (_, index) => index !== slotIndex,
    );

    await firebase.firestore().collection('slot_patterns').doc(slotPatternId).update(slotPattern);

    setModalSlotIsOpen(false);
  };

  const EditSlotModal = (prop: {
    deliveryGroups: Array<{ id: string; name: string; limitCurriesPerSlot: number | null; position: number }>;
  }) => {
    return (
      <Modal isOpen={modalSlotIsOpen} style={customStyles} contentLabel="スロット編集">
        <div>
          <form id="editSlot" onSubmit={handleSubmitSlotModal}>
            <div className="form-group row">
              <div className="form-group col">
                開始 (HH:MM)
                <input type="text" name="time" defaultValue={line?.time || hourMinutes(slot.time)} />
              </div>
              <div className="form-group col">
                終了 (HH:MM)
                <input type="text" name="timeUntil" defaultValue={line?.time_until || hourMinutes(slot.time + 15)} />
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col">
                デリバリーグループ
                <select defaultValue={line?.delivery_group_id} name="deliveryGroupId">
                  <option value="">未選択</option>
                  {prop.deliveryGroups.map((deliveryGroup) => (
                    <>
                      <option value={deliveryGroup.id}>{deliveryGroup.name}</option>
                    </>
                  ))}
                </select>
                <small>デフォルトを上書きする場合設定</small>
              </div>
            </div>
            <div className="form-group row">
              <div className="form-group col-12">
                スロット固有の上限食数
                <input
                  type="text"
                  name="limitCurries"
                  size={40}
                  placeholder="設定する場合のみ入力"
                  defaultValue={line?.limit_curries || ''}
                />
              </div>
            </div>

            {batchDelivery && (
              <>
                <div className="form-group row">
                  <div className="form-group col-12">
                    注文の締め切り時間(分)
                    <input
                      type="text"
                      name="batchDeliveryCloseMinutes"
                      size={40}
                      placeholder="デフォルトを上書きする場合入力"
                      defaultValue={line?.batch_delivery_close_minutes || ''}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-12">
                    注文締め切りから盛り付け開始までの時間(分)
                    <input
                      type="text"
                      name="batchDeliveryDelayedCookingMinutes"
                      size={40}
                      placeholder="(通常は入力しない)"
                      defaultValue={line?.batch_delivery_delayed_cooking_minutes || ''}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-12">
                    盛り付けスロット終了〜出発までの時間
                    <input
                      type="text"
                      name="minutesFromSlotSetToDelivery"
                      size={40}
                      placeholder="設定する場合のみ入力(未設定の場合は5分)"
                      defaultValue={line?.minutes_from_slot_set_to_delivery || ''}
                    />
                  </div>
                </div>
              </>
            )}

            <input type="hidden" name="batchDelivery" value={batchDelivery ? 'true' : 'false'} />

            <div className="form-group row">
              <small className="text-danger">{slotUpdateErrorMessage}</small>
            </div>

            <div className="form-group row">
              <div className="col-12">
                <input type="submit" className="btn btn-primary" value="更新" />
                <input
                  type="button"
                  className="btn btn-dark"
                  value="キャンセル"
                  onClick={(e) => {
                    cancelEditSlot(e);
                  }}
                />
                {slotIndex >= 0 ? (
                  <button
                    type="button"
                    className="btn btn-danger float-right"
                    onClick={(e) => {
                      deleteSlot(e);
                    }}
                  >
                    削除
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  const DeliveryGroupName = (deliveryGroupId: string) => {
    const group = props.deliveryGroups.find((g) => g.id === deliveryGroupId);
    if (group) {
      return <> ({group.name})</>;
    }
    return <></>;
  };

  if (line) {
    const span = diffMinutes(line.time, line.time_until) / 5;

    // これはストアでの表示用のロジックで実際の注文を受けるときのロジックはサーバー側にあるので、このロジックを変更する場合はサーバー側も変更すること tmc-firebase/functions/src/slot_patterns_service.ts
    const lineSlot = line.batch_delivery
      ? [...slotPattern.lines].find(
          (l) => l.time > hourMinutes(slot.time - batchDeliveryCloseMinutes + batchDeliveryDelayedCookingMinutes),
        )
      : [...slotPattern.lines].reverse().find((l) => l.time <= hourMinutes(slot.time));

    return (
      <td
        rowSpan={span}
        style={{
          backgroundColor: lineSlot ? makeColorFromTime(lineSlot.time) : '#ffffff',
          border: '1px solid black',
        }}
      >
        <EditSlotModal deliveryGroups={props.deliveryGroups} />
        <div className="slot">
          <button
            type="button"
            onClick={(e) => {
              openEditSlot(e);
            }}
          >
            <i className="far fa-edit fa-xs" />
          </button>
          {line.time}-{line.time_until}
          <br /> (
          {batchDeliveryCloseMinutes && (
            <>
              〆
              {dayjs(`${dayjs().tz('Asia/Tokyo').format('YYYY-MM-DD')} ${line.time}`, 'YYYY-MM-DD HH:mm')
                .tz('Asia/Tokyo')
                .subtract(batchDeliveryCloseMinutes, 'minutes')
                .format('HH:mm')}
            </>
          )}{' '}
          {lineSlot && (
            <span style={{ whiteSpace: 'nowrap' }}>
              <i className="far fa-cauldron" /> {lineSlot.time}
            </span>
          )}
          )
          <br />
          {line.batch_delivery && (
            <>
              {batchDeliveryCloseMinutes} 分前締切
              {line.limit_curries && ` 最大${line.limit_curries}食`}
              {line.delivery_group_id && DeliveryGroupName(line.delivery_group_id)}
            </>
          )}
          {!lineSlot ? (
            <>
              <br />
              <span className="alert alert-danger">ラインが存在しません</span>
            </>
          ) : null}
        </div>
      </td>
    );
  }
  const line2 = slotPatternForShop
    ? slotPatternForShop.slots.find((s) => s.time < hourMinutes(slot.time) && s.time_until > hourMinutes(slot.time))
    : null;
  if (line2) {
    return <></>;
  }
  return (
    <td>
      <EditSlotModal deliveryGroups={props.deliveryGroups} />
      <button
        type="button"
        onClick={(e) => {
          openEditSlot(e);
        }}
      >
        <i className="far fa-plus fa-xs" />
      </button>
    </td>
  );
}

function ShopSlotPatternDetail(props: {
  shop: firebase.firestore.DocumentSnapshot;
  childShops: Array<{ id: string; data: firebase.firestore.DocumentData }>;
  slotPattern: firebase.firestore.DocumentSnapshot;
}) {
  const { shops } = useContext(ShopsContext);

  const [slotPatternId] = useQueryParam('slot_pattern_id', withDefault(StringParam, ''));

  const startHours = Number.parseInt(process.env.REACT_APP_start_hour || '10', 10);
  const openingHours = Number.parseInt(process.env.REACT_APP_opening_hours || '6', 10);
  const timeSlots: Array<{ time: number; timeUntil: number }> = [];

  const slotPattern = props.slotPattern.data()!;

  const [deliveryGroups, setDeliveryGroups] = useState<
    Array<{ id: string; name: string; limitCurriesPerSlot: number | null; position: number }>
  >([]);

  useEffect(() => {
    setDeliveryGroups(
      props.slotPattern.data()!.delivery_groups
        ? Object.keys(props.slotPattern.data()!.delivery_groups)
            .map((deliveryGroupId) => {
              const data = props.slotPattern.data()!.delivery_groups[deliveryGroupId];
              return {
                id: deliveryGroupId,
                name: data.name,
                limitCurriesPerSlot: data.limit_curries_per_slot,
                position: data.position,
              };
            })
            .sort((a, b) => a.position - b.position)
        : [],
    );
  }, [props.slotPattern]);

  for (let i = 0; i <= openingHours * 12 - 1; i += 1) {
    timeSlots.push({
      time: startHours * 60 + i * 5,
      timeUntil: startHours * 60 + i * 5 + 5,
    });
  }

  const lineSlot = (slot: { time: number; timeUntil: number }) => {
    const lineIndex = slotPattern.lines.findIndex((line) => line.time === hourMinutes(slot.time));
    const line = lineIndex >= 0 ? slotPattern.lines[lineIndex] : null;
    return (
      <LineItem
        line={line}
        slotPattern={slotPattern}
        lineIndex={lineIndex}
        slot={slot}
        slotPatternId={slotPatternId}
        kitchenShopId={props.shop.id}
      />
    );
  };

  const slotForCustomer = (slot: { time: number; timeUntil: number }, childShopId: string) => {
    const slotPatternForShopIndex = slotPattern.shops.findIndex((s) => s.id === childShopId);

    const shopData = shops[childShopId].data()!;

    const batchDelivery = shopData.batch_delivery as boolean;

    const slotPatternForShop = slotPatternForShopIndex >= 0 ? slotPattern.shops[slotPatternForShopIndex] : null;
    const slotIndex = slotPatternForShop
      ? slotPatternForShop.slots.findIndex((s) => s.time === hourMinutes(slot.time))
      : -1;

    const line = slotIndex >= 0 ? slotPatternForShop.slots[slotIndex] : null;

    const batchDeliveryCloseMinutes = (line?.batch_delivery_close_minutes ||
      shops[childShopId].data()!.batch_delivery_close_minutes) as number;
    const batchDeliveryDelayedCookingMinutes = line?.batch_delivery_delayed_cooking_minutes || null;

    return (
      <SlotItem
        key={childShopId}
        line={line}
        slotPattern={slotPattern}
        slot={slot}
        batchDelivery={batchDelivery}
        batchDeliveryCloseMinutes={batchDeliveryCloseMinutes}
        batchDeliveryDelayedCookingMinutes={batchDeliveryDelayedCookingMinutes}
        slotIndex={slotIndex}
        slotPatternId={slotPatternId}
        kitchenShopId={props.shop.id!}
        shopId={childShopId}
        slotPatternForShop={slotPatternForShop}
        deliveryGroups={deliveryGroups}
      />
    );
  };

  return (
    <div className="container-fluid h-100">
      <hr />
      <table className="slot-pattern" style={{ emptyCells: 'show' }}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th className="line">ライン</th>
            {props.childShops.map((childShop) => {
              return (
                <th className="slot" key={childShop.id}>
                  <small>
                    <div className="shopname">{childShop.data.short_name}</div>
                    <div>
                      {slotPattern.settings[childShop.id]?.delivery_group_id &&
                        deliveryGroups.find(
                          (group) => group.id === slotPattern.settings[childShop.id]?.delivery_group_id,
                        )?.name}
                    </div>
                  </small>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {timeSlots.map((slot) => {
            return (
              <tr key={slot.time}>
                <th className="time" style={{ border: '1px solid' }}>
                  {hourMinutes(slot.time)}
                </th>
                {lineSlot(slot)}
                {props.childShops.map((childShop) => {
                  return slotForCustomer(slot, childShop.id);
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ShopSlotPatternDetail;
